import React from 'react';
import styled from 'styled-components';
import { FaUserAlt, FaHandsHelping } from 'react-icons/fa';

import Seo from '../components/SEO'
import IconContainer from '../components/IconContainer';
import PageBanner from '../components/PageBanner';

// import registrationBannerImage from '../images/banner-images/registration-banner.jpg'
import paymentQR from '../images/sarpl-pay-qr-400.jpg'
import { graphql, Link } from 'gatsby';

const RegisterPage = ({ data }) => {
  const bannerImage = data.file.childImageSharp
  const nextEventID = data.allSanityEvent.nodes[0]?._id
  
  return (
    <>
      <Seo title='Registration' image={`https://www.sarpl.co.za${data.file.publicURL}`} />
      <StyledRegisterPage>
        <PageBanner title='Registration' image={bannerImage} imagePosition='center 20%' />

        <div className="page-container">
          <section className='instructions-card'>
            <IconContainer icon={<FaUserAlt />} iconSize='3rem' />
            <h2>How to Register</h2>
            <div className='instructions-card-content'>
              <h3>Step 1: Create Player Profile</h3>
              <p>
                Create your player profile and register. <br />
                If you've already created a player profile previously there is no need to do it again. <br />
                *Please upload a valid profile picture.
              </p>
              <a className='btn-alt' href="https://form.jotform.com/203333495712554" target='_blank' rel='noreferrer'>Signup</a>
              <p className='small-text'>
                <small>
                  *If you want to register for an event, go to the <Link to={nextEventID ? `/events/${nextEventID}/` : "./#"}>next upcoming event page</Link>. {!nextEventID && "But currently there are no upcoming events."}
                </small>
              </p>

              <h3>Step 2: Pay Player Fees</h3>
              <ul>
                <li>Season Fee (five-man only) - R400*</li>
                <li>Incidental Fee (one five-man event) - R200*</li>
              </ul>
              <small>*Fees for 2022</small>

              <h4>Banking Details</h4>
              <p>
              SARPL <br />
              FNB Cradlestone 250655 <br />
              Acc no: 62463655004 <br />
              Ref. Initial Surname DOB
              </p>
              <p className='ta-center'>or</p>
              <h4>Scan to Pay</h4>
              <img src={paymentQR} alt="Scan to pay" />
              <small className='ta-center'>*You can use Zapper, Snapscan, etc.</small>
            </div>
          </section>

          <section className='assistance-card'>
            <IconContainer icon={<FaHandsHelping />} iconSize='2rem' containerSize='4.5rem' bgColor='var(--secondary-color)' />
            <h2>Need Help?</h2>
            <p>If you have any problems or need assistance, please don’t hesitate to contact us.</p>
            <a className='btn-alt' href="mailto:national@sarpl.co.za?Subject=Registration Assistance">national@sarpl.co.za</a>
          </section>
        </div>
      </StyledRegisterPage>
    </>
  );
};

export const query = graphql`
  query RegistrationPageQuery($currentISODate: Date) {
    file(name: {eq: "registration-banner"}) {
      childImageSharp {
        gatsbyImageData
      }
      publicURL
    }
    allSanityEvent(
      filter: {startDate: {gt: $currentISODate}}
      sort: {fields: startDate}
    ) {
      nodes {
        _id
      }
    }
  }
`


const StyledRegisterPage = styled.main`
  line-height: 1.5;
  
  .page-container {
    padding: 0 1rem;
  }

  p.small-text {
    color: #ccc;
    line-height: 0.9;
  }

  .btn-alt {
    display: block;
    color: var(--secondary-color);
    width: fit-content;
    margin: auto;
    padding: 0.5em 2em;
    border: solid 1px var(--primary-color);
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.5s color;

    &:hover {
      background-color: var(--primary-color);
      color: #fafafa;
    }
  }

  .instructions-card {
    max-width: 400px;
    margin: 3rem auto 4rem auto;

    .btn-alt {
      margin-bottom: 1rem;
    }

    h2, h3, h4 {
      margin-bottom: 1rem;
      text-align: center;
    }

    h2 {
      margin: 1.5rem 0;
    }

    h3 {
      position: relative;
      padding-bottom: 0.4rem;

      &:after {
        content: '';
        background-color: var(--secondary-color);
        width: 50px;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    p:not(:last-child) {
      margin-bottom: 1rem;
      line-height: 1.2;
    }

    p.small-text {
      margin-bottom: 1.5rem;
    }

    ul {
      list-style: '- ' inside;
    }

    img {
      display: block;
      width: 60%;
      margin: 0 auto 1rem auto;
    }

    &-content {
      background-color: #222;
      padding: 2rem;
      border-radius: 20px;
    }
  }

  .assistance-card {
    max-width: 400px;
    margin: 2rem auto 4rem auto;
    text-align: center;

    h2 {
      margin: 1rem 0;
    }

    .btn-alt {
      margin-top: 1rem;
    }
  }
`

export default RegisterPage;
