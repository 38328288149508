import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const PageBanner = ({ title, image, imagePosition }) => {
  return (
    <StyledPageBanner style={{ display: 'grid'}}>
      <GatsbyImage 
        image={getImage(image)} 
        alt='banner'
        style={{ gridArea: '1/1' }} 
        objectPosition={imagePosition} 
        loading='eager'
      />
      <div style={{ gridArea: '1/1', zIndex: 1 }}>
        <h1>{title}</h1>
      </div>
    </StyledPageBanner>
  );
};

const StyledPageBanner = styled.div`
  height: 14rem;
  
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 100, 155, 0.6);
  }

  h1 {
    font-size: 3rem;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  @media (max-width: 480px) {
    height: 12rem;

    h1 {
      font-size: 2.2rem;
    }
  }
`

export default PageBanner;
