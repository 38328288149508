import React from 'react';
// import PropTypes from 'prop-types'
import styled from 'styled-components';

const IconContainer = ({ icon, iconSize, containerSize, bgColor }) => {
  return (
    <StyledIconContainer className='icon-container' iconSize={iconSize} containerSize={containerSize} bgColor={bgColor} >
      {icon}
    </StyledIconContainer>
  );
};

const StyledIconContainer = styled.div`
  background-color: ${props => props.bgColor || 'var(--primary-color)'};
  width: ${props => props.containerSize || '2em'};
  height: ${props => props.containerSize || '2em'};
  margin: auto;
  border-radius: 50%;
  position: relative;
  font-size: ${props => props.iconSize || '1rem'};

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

// IconContainer.propTypes = {
//   icon: PropTypes.oneOf(['svg']).isRequired
// }

export default IconContainer;
